@import 'src/styles/font-stack';

.footer {
  border: 0.0625rem solid var(--color-dark-blue);
  border-radius: 0.375rem;
  display: flex;
  margin-top: auto;

  > div {
    padding: 0.75rem 1rem;
  }

  > :first-child {
    border-right: 0.0625rem solid var(--color-dark-blue);
  }

  &.dark {
    border: 0.0625rem solid rgba(255, 255, 255, 0.6);

    > :first-child {
      border-right: 0.0625rem solid rgba(255, 255, 255, 0.6);
    }
  }
}

.loanHeader {
  @include font-overline-10;
  margin: 0;
}

.monthly {
  @include font-semibold-16;
  margin: 0;
  color: var(--color-sky-blue-300);
  white-space: nowrap;
}

.price {
  @include font-semibold-12;
  margin: 0;
}

.checkHeading {
  @include font-regular-12;
  margin: 0;
}

.check {
  @include font-semibold-12;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:nth-child(2) {
    line-height: 1.5rem;
  }
}

@import 'src/styles/font-stack';
@import 'src/styles/media';

.wrapper {
  color: var(--color-stone-grey);
  width: 100%;
  max-width: var(--max-size-lg);
  margin: -3.8rem auto 0;
  min-height: 35rem;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: clamp(4rem, 30%, 8rem) 1.5rem 3.5rem;
  border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;

  @include media(md) {
    border-radius: var(--border-radius-large);
    padding: clamp(4rem, 45%, 5rem) 10rem 8rem;
    justify-content: center;
    margin-top: 0;
    min-height: 45rem;
  }

  &.has-chevron {
    @include media(md) {
      padding-bottom: 5.625rem;
    }
  }

  &.small {
    height: clamp(35rem, 60vh, 40rem);
  }

  .texts {
    z-index: var(--layer-ground);
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.full-length {
      max-width: 100%;
    }

    &.half-length {
      max-width: 31.25rem;
    }

    &.top-layer {
      z-index: var(--layer-moon);
    }
  }

  h1 {
    @include font-display-46;
    margin-bottom: 1rem;

    @include media(lg) {
      @include font-display-62;
    }
  }

  p {
    @include font-intro-24;
  }

  .hero-button {
    @include media(md) {
      max-width: 15rem;
    }
    padding-bottom: 4rem;
  }
  .arrow-down {
    position: relative;
    transform: translateX(50%);
    margin-top: auto;
    margin-bottom: 0;
    animation: bounce 0.5s ease-in-out infinite alternate;
  }

  @keyframes bounce {
    from {
      top: -0.375rem;
    }

    to {
      top: 0.375rem;
    }
  }
}

/*
 * Simple media queries mixin
 * Inspired by https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/
 * @author Jesper Ekstrand (Osynlig AB)
 */

/*
 * A map of default breakpoints.
 * Use it or declare a $breakpoints variable in your variables file or similar to override this
 *
 * Suggested naming convention for sizes in between base breakpoints:
 * Append an approximate number between 1 and 99 to indicate where on the interval between
 * the base breakpoint and the next breakpoint this new breakpoint is.
 * Examples:
 * Between md and large: md-50
 * Slightly larger than md: md-10
 * Almost as big as lg: md-90
 */
$breakpoints: (
  xs: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

/*
 * media mixin
 * Usage: @include media(sm) {} or @include media(1024px)
 */
@mixin media($breakpoint) {
  $breakpoint-value: 0;

  // If the breakpoint exists in the map, use that instead.
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
  } @else {
    // Fallback to using the original value as breakpoint,
    $breakpoint-value: $breakpoint;
  }

  // Write the media query.
  @media (min-width: $breakpoint-value) {
    @content;
  }
}

/*
 * media mixin for high resolution devices
 * Usage: @include media-high-resolution {}
 */
@mixin media-high-resolution {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@import 'src/styles/font-stack';
@import 'src/styles/not-supports-gap';
@import 'src/styles/media';

.form {
  height: 100%;
  display: flex;
  min-height: 0;
  flex-direction: column;
}

.input-range-wrapper {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label-wrapper {
  @include font-regular-20;
  display: flex;
  justify-content: space-between;
}

.label {
  font-weight: 500;
  font-variation-settings: 'wght' 500;
  margin: 0;
}

.content-wrapper {
  overflow: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 4rem; // to not overlap bottom buttons
}

.content-inner-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include not-supports-gap {
    > label + label,
    > label + div,
    > div + div {
      margin-top: 1.5rem;
    }
  }
}

.gearbox-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.gearbox-buttons-wrapper {
  display: flex;
  gap: 1rem;

  @include not-supports-gap {
    > button + button {
      margin-left: 1rem;
    }
  }
}

.bottom-content-wrapper {
  display: flex;
  gap: 1rem;
  background-color: var(--color-dark-blue-700);
  margin-top: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
  padding-top: 1rem;

  @include not-supports-gap {
    > button + button {
      margin-left: 1rem;
    }
  }
}

.locationLabel {
  @include media(lg) {
    display: none;
  }
}

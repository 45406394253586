@import 'src/styles/media';

.locationDropdown {
  position: relative;
  display: none;

  select {
    background-color: var(--color-white);
    font-family: var(--font-family);
    border-style: solid;
    border-width: 0.125rem;
    border-color: var(--color-dark-blue-100);
    transition: border 300ms ease-out;
    border-radius: 1rem;
    padding-left: 2.625rem;
    height: 100%;
  }

  @include media(lg) {
    display: block;
  }
}

.iconWrapper {
  position: absolute;
  top: 1.25rem;
  left: 0.75rem;
}

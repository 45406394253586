@import 'src/styles/media';

.form {
  display: flex;
  margin-bottom: 1.5rem;
  gap: 1.5rem;

  @include media(sm) {
    margin-bottom: 2rem;
  }
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.input {
  > div {
    margin: 0;
  }

  input {
    padding-left: 1rem;

    @include media(sm) {
      padding-left: 1.5rem;
    }
  }

  label {
    font-size: 1.125rem;
    top: 1.625rem;
    left: 1.25rem;

    @include media(sm) {
      font-size: 1.5rem;
      top: 1.375rem;
      left: 1.625rem;
    }
  }
}

.buttonWrapper {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: max-content;
}

.wrapper {
  position: relative;
  input {
    padding-right: 120px;
  }

  button {
    padding: 0.75rem 1.5rem;
  }

  .searchRow {
    all: unset;
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 1rem;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    &:last-of-type {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    &:hover {
      background: var(--color-dark-blue-100);
    }
  }
}

.searchDropdown {
  z-index: 5;
  position: absolute;
  color: black;
  background: white;
  width: 100%;
  top: 80px;
  border-radius: 1rem;
}

.button {
  cursor: pointer;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

@import 'src/styles/font-stack';
@import 'src/styles/not-supports-gap';

.filter-button {
  @include font-semibold-20;
  background-color: var(--color-transparent);
  border: 1px solid #fff;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  color: var(--color-white);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// future proof if animation enabled easier to have overlay separated in own element
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 51, 0.5);
  border: none;
}

.content-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-dark-blue-900);
  display: flex;
  flex-direction: column;
  width: clamp(20rem, 80%, 30rem); // 320px min value for tiny devices
  color: var(--color-white);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.title {
  @include font-title-32;
  margin: 0;
}

.close-btn {
  @include font-semibold-14;
  color: var(--color-white);
  display: flex;
  align-items: center;
  background-color: var(--color-transparent);
  border: none;
  gap: 0.5rem;
  cursor: pointer;

  @include not-supports-gap {
    > span {
      margin-left: 0.5rem;
    }
  }
}

.status-wrapper {
  color: var(--color-white);
  width: 200px;
  margin: 0 auto;
  margin-top: calc(100vh / 5); // magic value
  text-align: center;

  video {
    width: 200px;
  }
}

@import 'src/styles/font-stack';

.select {
  border-radius: 0.625rem;
  border: 0.125rem solid var(--color-white);
  outline: none;
  font-family: var(--font-family);
  color: var(--color-dark-blue);
  background: var(--color-white) url('/assets/icons/default/chevron-down.svg') no-repeat top
    0.8906rem right 0.8906rem / 1rem 1rem;
  appearance: none;
  cursor: pointer;

  &:focus {
    border: 0.125rem solid var(--color-mint-sweet);
  }

  &--fullwidth {
    width: 100%;
  }

  &--small {
    @include font-input-value-16;
    padding: 0.825rem;
    padding-right: 3rem;
    background-position-y: 0.7rem;
  }

  &--medium {
    @include font-input-value-20;
    padding: 1.125rem;
    padding-right: 3rem;
    background-position-y: 1.25rem;
  }

  &--large {
    @include font-input-value-24;
    padding: 1.325rem;
    padding-right: 3rem;
    background-position-y: 1.5rem;
  }

  &:disabled {
    cursor: default;
    color: var(--color-dark-blue-100);
    background-color: var(--color-stone-grey-900);
    border-color: var(--color-stone-grey-900);
  }
}

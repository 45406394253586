/*
 * Display
 */
@mixin _font-display {
  font-weight: var(--font-weight-bold);
  font-variation-settings: 'wght' var(--font-weight-bold);
  line-height: 1.2;
  letter-spacing: -0.02em;
}

@mixin font-display-70 {
  @include _font-display;
  font-size: 4.375rem;
}

@mixin font-display-62 {
  @include _font-display;
  font-size: 3.875rem;
}

@mixin font-display-50 {
  @include _font-display;
  font-size: 3.125rem;
}

@mixin font-display-46 {
  @include _font-display;
  font-size: 2.875rem;
}

/*
 * Title
 */
@mixin _font-title {
  font-weight: var(--font-weight-semibold);
  font-variation-settings: 'wght' var(--font-weight-semibold);
  line-height: 1.2;
  letter-spacing: -0.02em;
}

@mixin font-title-42 {
  @include _font-title;
  font-size: 2.625rem;
}

@mixin font-title-32 {
  @include _font-title;
  font-size: 2rem;
}

@mixin font-title-24 {
  @include _font-title;
  font-size: 1.5rem;
  line-height: 1.2;
}

@mixin font-title-20 {
  @include _font-title;
  font-size: 1.25rem;
  line-height: 1.2;
}

@mixin font-title-18 {
  @include _font-title;
  font-size: 1.125rem;
  line-height: 1.2;
}

@mixin font-title-16 {
  @include _font-title;
  font-size: 1rem;
  line-height: 1.2;
}

/*
 * Intro
 */
@mixin _font-intro {
  font-weight: var(--font-weight-regular);
  font-variation-settings: 'wght' var(--font-weight-regular);
  line-height: 1.6;
  letter-spacing: -0.02em;
}

@mixin font-intro-32 {
  @include _font-intro;
  font-size: 2rem;
}

@mixin font-intro-24 {
  @include _font-intro;
  font-size: 1.5rem;
}

@mixin font-intro-18 {
  @include _font-intro;
  font-size: 1.125rem;
}

/*
 * Regular
 */
@mixin _font-regular {
  font-weight: var(--font-weight-regular);
  font-variation-settings: 'wght' var(--font-weight-regular);
  line-height: 1.6;
  letter-spacing: -0.02em;
}

@mixin font-regular-20 {
  @include _font-regular;
  font-size: 1.25rem;
}

@mixin font-regular-18 {
  @include _font-regular;
  font-size: 1.125rem;
}

@mixin font-regular-16 {
  @include _font-regular;
  font-size: 1rem;
}

@mixin font-regular-14 {
  @include _font-regular;
  font-size: 0.875rem;
}

@mixin font-regular-12 {
  @include _font-regular;
  font-size: 0.75rem;
}

/*
 * Semibold
 */
@mixin _font-semibold {
  font-weight: var(--font-weight-semibold);
  font-variation-settings: 'wght' var(--font-weight-semibold);
  line-height: 1.6;
  letter-spacing: -0.02em;
}

@mixin font-semibold-20 {
  @include _font-semibold;
  font-size: 1.125rem;
}

@mixin font-semibold-18 {
  @include _font-semibold;
  font-size: 1.125rem;
}

@mixin font-semibold-16 {
  @include _font-semibold;
  font-size: 1rem;
}

@mixin font-semibold-14 {
  @include _font-semibold;
  font-size: 0.875rem;
}

@mixin font-semibold-12 {
  @include _font-semibold;
  font-size: 0.75rem;
}

/*
 * Overline
 */
@mixin _font-overline {
  font-weight: var(--font-weight-medium);
  font-variation-settings: 'wght' var(--font-weight-medium);
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@mixin font-overline-20 {
  @include _font-overline;
  font-size: 1.25rem;
}

@mixin font-overline-16 {
  @include _font-overline;
  font-size: 1rem;
}

@mixin font-overline-14 {
  @include _font-overline;
  font-size: 0.875rem;
}

@mixin font-overline-12 {
  @include _font-overline;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

@mixin font-overline-10 {
  @include _font-overline;
  font-size: 0.625rem;
  line-height: 1.125rem;
}

/*
 * Button
 */
@mixin _font-button {
  font-family: var(--font-family);
  font-weight: var(--font-weight-semibold);
  font-variation-settings: 'wght' var(--font-weight-semibold);
  line-height: 1;
}

@mixin font-button-20 {
  @include _font-button;
  font-size: 1.25rem;
}

@mixin font-button-16 {
  @include _font-button;
  font-size: 1rem;
}

@mixin font-button-14 {
  @include _font-button;
  font-size: 0.875rem;
}

@mixin font-button-12 {
  @include _font-button;
  font-size: 0.75rem;
}

/*
 * Input label
 */
@mixin _font-input-label {
  font-weight: var(--font-weight-medium);
  font-variation-settings: 'wght' var(--font-weight-medium);
  line-height: 1;
}

@mixin font-input-label-24 {
  @include _font-input-label;
  font-size: 1.5rem;
}

@mixin font-input-label-20 {
  @include _font-input-label;
  font-size: 1.25rem;
}

@mixin font-input-label-16 {
  @include _font-input-label;
  font-size: 1rem;
}

@mixin font-input-label-14 {
  @include _font-input-label;
  font-size: 0.875rem;
}

/*
 * Input value
 */
@mixin _font-input-value {
  font-weight: var(--font-weight-regular);
  font-variation-settings: 'wght' var(--font-weight-regular);
  line-height: 1;
}

@mixin font-input-value-24 {
  @include _font-input-value;
  font-size: 1.5rem;
}

@mixin font-input-value-20 {
  @include _font-input-value;
  font-size: 1.25rem;
}

@mixin font-input-value-18 {
  @include _font-input-value;
  font-size: 1.125rem;
}

@mixin font-input-value-16 {
  @include _font-input-value;
  font-size: 1rem;
}

@mixin font-input-value-14 {
  @include _font-input-value;
  font-size: 0.875rem;
}

/*
 * Link Regular
 */
@mixin _font-link-regular {
  font-weight: var(--font-weight-regular);
  font-variation-settings: 'wght' var(--font-weight-regular);
  line-height: 1.6;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
}

@mixin font-link-regular-20 {
  @include _font-link-regular;
  font-size: 1.25rem;
}

@mixin font-link-regular-18 {
  @include _font-link-regular;
  font-size: 1.125rem;
}

@mixin font-link-regular-16 {
  @include _font-link-regular;
  font-size: 1rem;
}

@mixin font-link-regular-14 {
  @include _font-link-regular;
  font-size: 0.875rem;
}

@mixin font-link-regular-12 {
  @include _font-link-regular;
  font-size: 0.75rem;
}

/*
 * Link Semibold
 */
@mixin _font-link-semibold {
  font-weight: var(--font-weight-semibold);
  font-variation-settings: 'wght' var(--font-weight-semibold);
  line-height: 1.6;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
}

@mixin font-link-semibold-18 {
  @include _font-link-semibold;
  font-size: 1.125rem;
}

@mixin font-link-semibold-16 {
  @include _font-link-semibold;
  font-size: 1rem;
}

@mixin font-link-semibold-14 {
  @include _font-link-semibold;
  font-size: 0.875rem;
}

@mixin font-link-semibold-12 {
  @include _font-link-semibold;
  font-size: 0.75rem;
}

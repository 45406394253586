@import 'src/styles/font-stack';

.checkbox {
  display: inline-flex;
  align-items: center;
  column-gap: 0.75rem;
  position: relative;
  cursor: pointer;

  input {
    height: 2.75rem;
    width: 2.75rem;
    margin: 0;
    appearance: none;
    border-radius: 0.25rem;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    &:checked {
      outline: 0.1875rem solid var(--color-white);
    }
  }

  &:hover {
    input:not(:checked):not(:indeterminate):not(:disabled) {
      border-color: var(--color-white);
    }
  }
}

@import 'src/styles/font-stack';
@import 'src/styles/media';

.content-wrapper {
  padding: 2rem 1.25rem;
  border-radius: 1.5rem;
  background-color: var(--color-dark-blue-900);
  z-index: 20;
  position: relative;
}

.loading-wrapper {
  color: var(--color-white);
  width: 200px;
  margin: 0 auto;
  text-align: center;

  video {
    width: 200px;
  }

  @include media(md) {
    width: 400px;

    video {
      width: 400px;
    }
  }
}

.no-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max-size-md);

  @include media(md) {
    flex-direction: row;
    gap: 10rem;
  }
}

.productList {
  max-width: var(--max-size-md);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18.125rem, 1fr));
  gap: 2.5rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;

  @include media(md) {
    gap: 1.5rem;
  }
}

.filter-mobile-wrapper {
  margin-bottom: 1.5rem;
}

.number-of-cars {
  @include font-semibold-14;
  color: var(--color-white);

  @include media(md) {
    @include font-regular-16;
  }
}

.car-length {
  @include font-regular-14;
}

.paginate-wrapper {
  display: flex;
  justify-content: center;
}

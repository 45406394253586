@import 'src/styles/font-stack';

.badge {
  @include font-button-16;
  border-radius: 0.625rem;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  white-space: nowrap;
  border: 0.0625rem solid;
}

.solid {
  border-color: var(--color-transparent);
  background-color: var(--stone-grey-700);
}

.ghost {
  background-color: var(--color-transparent) !important;
}

.inverted {
  color: var(--color-white);
  border-color: var(--color-white);
}

.tiny {
  @include font-semibold-12;
  border-radius: 0.375rem;
  padding: 0.125rem 0.5rem;
  line-height: 1.5;
}

.small {
  @include font-button-14;
  padding: 0.5rem;
}

.medium {
  padding: 0.75rem 1.25rem;
}

.large {
  padding: 1rem 1.25rem;
}

.questionmark {
  span:last-child {
    margin-left: 0.3rem;
  }
}

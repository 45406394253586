@import 'src/styles/media';

.gutter {
  margin-bottom: 0;
}

.negative {
  margin-block-start: var(--vertical-gutter-negative);
}

.tiny {
  margin-block-start: var(--vertical-gutter-tiny);
}

.small {
  margin-block-start: var(--vertical-gutter-small);
}

.medium {
  margin-block-start: var(--vertical-gutter-medium);
}

.large {
  margin-block-start: var(--vertical-gutter-large);
}

.xlarge {
  margin-block-start: var(--vertical-gutter-xlarge);
}

@import 'src/styles/font-stack';

.paginate {
  display: flex;
  gap: 0.75rem;
  width: 320px; // magic number, minimal width in mobile layout. prevents jumpy behavior when click next button
  justify-content: space-between;
}

.btn-arrow {
  display: flex;
  align-items: center;
  background-color: var(--color-transparent);
  border: none;
}

.btn-number {
  @include font-regular-18();
  background-color: var(--color-transparent);
  color: var(--color-white);
  text-decoration: none;
  border-radius: 0.25rem;
  width: 2rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
}

.btn-number--active {
  background-color: var(--color-yellowish-500);
  color: var(--color-dark-blue-900);
}

a.btn-number,
a.btn-arrow {
  cursor: pointer;
}

.list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
}

@import 'src/styles/font-stack';
@import 'src/styles/not-supports-gap';

.wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  max-width: 25rem; // 400px
  width: 100%;
  justify-self: center;
}

.card {
  position: relative;
  z-index: var(--layer-ground);
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  border-radius: 1rem;
  top: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
  background-color: var(--color-stone-grey-100);

  &:hover {
    background-color: var(--color-stone-grey-300);
  }
}

.badges {
  display: flex;
  gap: 0.375rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 11.875rem;
  overflow: hidden;
  border-radius: 0.75rem;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 0.5rem;
  margin: 1rem 0 0.5rem;
  position: relative;
  color: var(--color-dark-blue);
}

.title {
  @include font-title-20;

  margin-bottom: 0.125rem;
}

.subtitle {
  @include font-semibold-12;

  margin-bottom: 0.25rem;
}

.info {
  @include font-regular-12;
  color: var(--color-dark-blue-500);
  margin-bottom: 0.75rem;

  span + span::before {
    content: '•';
    margin: 0 0.25rem;
  }
}

.equipment {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.375rem;

  @include not-supports-gap {
    > span + span {
      margin-left: 0.5rem;
    }
  }

  .equipment-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 5.525rem;
    overflow: hidden;
  }

  .equipment-more {
    @include font-regular-12;
    color: var(--color-dark-blue-500);
  }
}

.dark {
  color: var(--color-white);

  &.equipment-more {
    color: var(--color-white);
  }

  &.card {
    background-color: var(--color-dark-blue);
    border: 0.09375rem solid; // 1.5px
    border-color: rgba(255, 255, 255, 0.6);
  }
}

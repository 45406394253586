@import '../../styles/media';
@import '../../styles/font-stack';

.no-results {
  color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  path {
    stroke-width: 1.5px; // magic value
  }

  :global(.car-action-circle),
  :global(.car-action-circle-line) {
    stroke: var(--color-yellowish-500);
  }

  :global(.battery-icon-electric) {
    stroke: var(--color-warning);
  }
}

.icon-wrapper {
  margin-bottom: 0.5rem;

  &.medium {
    margin-bottom: 2rem;
  }

  &--error.medium {
    span {
      height: 120px !important; // magic value, need to override default height because of icon is more wide than high

      @include media(md) {
        height: 200px !important; // magic value, need to override default height because of icon is more wide than high
      }
    }
  }
}

.title {
  @include font-regular-18;
  font-size: 1.25rem;
  margin-bottom: 0;
}

.link {
  color: var(--color-yellowish-500);
  @include _font-link-regular;
  text-decoration: none;
}

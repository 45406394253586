@import 'src/styles/not-supports-gap';

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  margin-top: 0.5rem;

  @include not-supports-gap {
    li {
      margin-right: 0.75rem;
    }
  }
}

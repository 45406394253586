@import 'src/styles/font-stack';

.wrapper {
  position: relative;
  display: block;
  width: 100%;
}

.slider {
  display: block;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.slider__track {
  display: block;
}

.slider__track-wrapper {
  position: relative;
  display: block;
}

.slider__track {
  background-color: var(--color-dark-blue-100);
  width: 100%;
  z-index: -1;
  border-radius: 4px;
  height: 2px;
}

.slider__range {
  position: absolute;
  top: 0;
  border-radius: 4px;
  height: 4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  display: block;
  width: 100%;
  pointer-events: none;
  position: absolute;
  height: 0;
  outline: none;
  margin: 0;

  &--dark-blue::-webkit-slider-thumb {
    background-color: var(--color-dark-blue);
  }

  &--dark-blue::-moz-range-thumb {
    background-color: var(--color-dark-blue);
  }

  &--sky-blue::-webkit-slider-thumb {
    background-color: var(--color-sky-blue);
  }

  &--sky-blue::-moz-range-thumb {
    background-color: var(--color-sky-blue);
  }
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

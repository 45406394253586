@import 'src/styles/font-stack';
@import 'src/styles/media';
@import 'src/styles/not-supports-gap';

.wrapper {
  position: relative;
  height: 100%;
  overflow: hidden;
  max-width: 25rem;
  width: 100%;
  justify-self: center;
  background-color: var(--color-dark-blue-700);
  border-radius: 1rem;
  padding: 1.6563rem 1.25rem;

  .content,
  .inner {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include not-supports-gap {
      > h3 + p,
      > div + button,
      > p + div {
        margin-top: 1.5rem;
      }
    }
  }

  .content {
    max-width: 15rem;
    min-width: 14rem;
    margin: auto;

    h3 {
      @include font-title-20;
      color: var(--color-white);
      margin: 0;
    }

    p {
      @include font-intro-18;
      color: var(--color-stone-grey-100);
      margin: 0;
      order: 5;
    }

    .number-plate {
      border: 0.125rem solid var(--color-black);
      border-radius: 0.625rem;
      display: flex;
      overflow: hidden;
      gap: 0.625rem;
      background-color: var(--color-white);

      input[type='text'] {
        border: none;
        outline: none;
        font-family: monospace;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 34px;
        letter-spacing: 0.5ch;
        font-kerning: normal;
        width: 100%;
        padding: 0;
        margin-bottom: 0.5rem;
      }
    }

    .error-message {
      @include font-regular-14;
      color: var(--color-error);
      line-height: 1.375rem;
      padding-left: 1.5rem;
    }
  }
}

.full {
  max-width: var(--max-size-sm);
  margin: 0 var(--horizontal-gutter);
  color: var(--color-white);

  @include media(sm) {
    .wrapper {
      max-width: unset;
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 2rem;
      justify-content: space-between;
      padding: 2rem;
      border-radius: 2rem;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr minmax(14rem, 15rem);
      grid-template-rows: auto;
      column-gap: 2rem;
      margin: 0;
      max-width: none;
      width: 100%;

      h3,
      p {
        grid-column: 1 / 1;
        align-self: start;
        justify-self: stretch;
      }

      h3 {
        @include font-title-32;
      }

      p {
        order: unset;
      }

      .inner {
        grid-column: 2 / 2;
        grid-row: 1 / 4;
      }
    }
  }

  @include media(md) {
    .wrapper {
      padding: 4rem;
      column-gap: 4rem;
    }

    .copy {
      h2 {
        @include font-title-32;
      }
    }
  }
}

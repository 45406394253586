@import 'src/styles/font-stack';
@import 'src/styles/media';

$hero-vertical-padding: 1.5rem;

.title {
  @include font-title-20;
  margin-bottom: 1rem;
  color: var(--color-white);
}

.list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 0.75rem;
  margin-bottom: 2rem;
}

.slider-wrapper {
  margin-right: -$hero-vertical-padding; // overrides hero padding to get full width
  margin-left: -$hero-vertical-padding; // overrides hero padding to get full width
  margin-bottom: 1rem;

  :global(.splide__slide:first-of-type) {
    margin-left: $hero-vertical-padding; // force first item to align with rest of content in hero
  }
}

.icon-touch-wrapper {
  display: flex;
  justify-content: flex-end;
}
